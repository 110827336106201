import React, { Component } from 'react'
import SectionHeading from "../../components/SectionHeading"
import CTAButton from "../../components/CTAButton"
import StarRating from "../../components/StarRating"
import { evReporting, peyton } from "../../assets/images"
import { ReactComponent as IconZoomIn } from "../../assets/icons/icon-zoom-in.svg"
import ImageModal from "../../components/ImageModal"
class SectionCTA extends Component {
  state = {
    open: false
  }

  handleZoomIn = () => {
    this.setState({ open: true })
  }

  render() {
    const { open } = this.state
    return (
      <div className="max-w-[1440px] mx-auto px-4 py-12">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="basis-1/2">
            <div
              className="relative"
              role="button"
              onClick={this.handleZoomIn}
            >
              <div className="bg-[url('./assets/images/eventledger-reporting.png')] bg-cover bg-x-center bg-y-top bg-no-repeat w-full h-[500px] rounded-lg"></div>
              <img src={evReporting} alt="Event Ledger Reporting" className="hidden" />
              <span
                className="absolute bottom-4 right-4 w-10 h-10 flex items-center justify-center bg-neutral-600/80 hover:bg-neutral-600 border border-neutral-200
                          rounded-full shadow-lg hover:shadow-none transition-colors duration-300"
                role="button"
                onClick={this.handleZoomIn}
              >
                <IconZoomIn className="w-4 text-white/60 hover:text-white transition-colors duration-300" />
              </span>
            </div>
            <ImageModal open={open} onClose={() => this.setState({ open: false })} image={evReporting} />
          </div>
          <div className="flex flex-col gap-8 basis-1/2">
            <SectionHeading
              title="Financial Management for Music's Biggest Stars"
              description="Start your free trial and experience the future of music business accounting and  see how Event Ledger can amplify your financial success."
              left
            />
            <div><CTAButton /></div>
            <div className="flex items-center gap-4 mt-4">
              <div>
                <img src={peyton} alt="" width={75} className="rounded-xl" />
              </div>
              <div className="flex-1 flex flex-col gap-2">
                <StarRating />
                <p className="italic mt-1">"Not sure how our company would run without this software.  As a Client Manager, I rely on it to reconcile invoices, categorize statements, and maintain meticulous financial records.  It just works…"</p>
                <div>
                  <span className="font-semibold">Peyton G</span>, <span className="text-neutral-500">Client Manager</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SectionCTA