import { Transition } from "@headlessui/react"
import React, { Component } from 'react'
import { benefitsTitle, featuresTitle, digitalInvoice, evReporting, invoiceWorkflow } from "../../assets/images"

const SlideInFadeIn = ({ children, show }) => (
  <Transition
    show={show}
    appear={true}
    enter="transition-all ease-in-out duration-500 delay-[200ms]"
    enterFrom="opacity-0 translate-x-6"
    enterTo="opacity-100 translate-x-0"
    leave="transition-all ease-in-out duration-500"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
)

const features_benefits = [
  { img_url: digitalInvoice, className: 'min-h-[200px]',
    feature: {
      title: 'Digital Invoice Management', 
      description: `With Event Ledger, you can securely store all your invoices and important documents in the cloud. 
      Attach invoice images to transactions for seamless approvals and effortless audits.`
    },
    benefit: {
      title: 'Simplify Tour Finances',
      description: `Never chase down concert receipts or lose track of tour expenses again. Event Ledger transforms messy 
      paper trails into organized digital records, giving you instant access to every festival invoice, venue contract, and tour receipt.`
    },
  },
  { img_url: evReporting, className: 'min-h-[320px]',
    feature: {
      title: 'One Cloud, Multiple Ledgers', 
      description: `Event Ledger's cloud-based, multi-entity general ledger is designed specifically for music management 
      firms like yours. Our integrated accounting system eliminates double entry and consolidates all your financial data 
      into one powerful platform. Manage your artists' finances with unparalleled efficiency and accuracy.`
    },
    benefit: {
      title: 'Scale Your Business',
      description: `Stop juggling multiple accounting systems and spreadsheets across your roster. Event Ledger's music-focused 
      platform lets you seamlessly manage finances for all your artists in one place – from emerging indie acts to major touring stars. 
      Save hours of manual data entry, spot financial trends across your entire roster, and scale your management firm without drowning 
      in administrative work.`
    },
  },
  { img_url: invoiceWorkflow, className: 'min-h-[200px]',
    feature: {
      title: 'Control Every Dollar', 
      description: `Event Ledger gives you complete control over your artists' finances with configurable approval workflows. 
      Set multiple levels of approval and integrate seamlessly with your bank to ensure authorized payments only.`
    },
    benefit: {
      title: `Secure Your Artists' Finances`,
      description: `Prevent unauthorized spending and reduce the risk of fraud by implementing multi-level approval workflows 
      for all artist-related expenses.`
    },
  },
]

class Features extends Component {
  state = {
    activeIdx: 0
  }

  render() {
    return (
      <div className="py-28 bg-neutral-800 text-white dark:bg-neutral-900" id="features">
        <div className="max-w-[1440px] px-4 mx-auto flex flex-col items-center xl:flex-row gap-10">
          <div className="basis-1/2">
            <div className="grid grid-cols-2">
              <div>
                <h2 className="text-5xl font-semibold mb-3 border-b border-neutral-700/50 pb-2 pr-4 hidden">Feature</h2>
                <img src={featuresTitle} alt="Features" className="h-[55px] mb-3" />
                <div>
                  <ContentList
                    type="feature"
                    items={features_benefits}
                    activeIdx={this.state.activeIdx}
                    onItemClick={(idx) => this.setState({ activeIdx: idx })}
                  />
                </div>
              </div>
              <div className="border-l border-neutral-700/50">
                <h2 className="text-5xl font-semibold mb-3 border-b border-neutral-700/50 pb-2 pl-3 hidden">Benefit</h2>
                <img src={benefitsTitle} alt="Benefits" className="h-[55px] mb-3 pl-3" />
                <div>
                  <ContentList
                    type="benefit"
                    items={features_benefits}
                    activeIdx={this.state.activeIdx}
                    onItemClick={(idx) => this.setState({ activeIdx: idx })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="basis-1/2">
          {features_benefits.map((item, idx) => (
          this.state.activeIdx === idx &&
          <SlideInFadeIn show={true} key={`feature-${idx}`}>
            <img src={item.img_url} alt="" className="rounded-lg" />
          </SlideInFadeIn>
          ))}
          </div>
        </div>
      </div>
    )
  }
}

const ContentList = ({ items, activeIdx, onItemClick, type }) => (
  <div className="flex flex-col gap-4">
    {items.map((item, idx) => (
      <div
        key={`feature-${idx}`}
        className={`px-3 py-2 border border-transparent rounded opacity-75 transition-all hover:opacity-100 
        data-[active]:opacity-100 data-[active]:bg-neutral-600/25 data-[active]:border-neutral-700/50
        ${type === 'feature' ? 'border-r-0 rounded-r-none' : 'rounded-l-none'}
        ${item.className}
        `}
        role="button"
        onClick={() => onItemClick(idx)}
        onMouseEnter={() => onItemClick(idx)}
        data-active={activeIdx === idx ? true : null}
      >
        <h3 className="font-medium text-lg mb-2">{item[type].title}</h3>
        <p className="text-gray-400/80">{item[type].description}</p>
      </div>
    ))}
  </div>
)

export default Features