import React, { Component } from 'react'
import CTAButton from "../../components/CTAButton"
import { heroImage } from "../../assets/images"
import { ReactComponent as IconZoomIn } from "../../assets/icons/icon-zoom-in.svg"
import ImageModal from "../../components/ImageModal"

class Hero extends Component {
  state = {
    open: false
  }

  handleZoomIn = () => {
    this.setState({ open: true })
  }

  render() {
    const { open } = this.state
    return (
      <div className="px-4 max-w-[1440px] mx-auto flex flex-col xl:flex-row items-center gap-7 py-16">
        <div className="text-center xl:text-left basis-1/2">
          <h1 className="text-3xl sm:text-4xl 2xl:text-5xl font-semibold mb-5 !leading-[1.2]">Platinum Level Financial Management for Music's Biggest Managers.</h1>
          <p className="text-xl mb-12">Streamline your finances, maximize artist earnings, and scale your management business with our powerful, all-in-one platform.</p>
          <div className="flex flex-col xl:flex-row xl:items-center xl:gap-8">
            <div className="flex-1">
              <CTAButton />
            </div>
            <small className="text-sm text-neutral-500 italic mt-4 xl:mt-0">- we have a white glove transfer service to help you move accounts away from your current accounting software</small>
          </div>
        </div>
        <div className="w-full xl:basis-1/2">
          <div
            className="bg-neutral-200/50 dark:bg-white/10 p-2 rounded-lg border border-neutral-300 dark:border-neutral-50/10 lg:max-w-[690px] mx-auto relative"
            role="button"
            onClick={this.handleZoomIn}
          >
            <div className="bg-[url('./assets/images/hero-image.png')] bg-cover bg-x-center bg-y-top bg-no-repeat w-full h-[350px] rounded-lg"></div>
            <img src={heroImage} alt="Event Ledger" className="hidden" />
            <span
              className="absolute bottom-4 right-4 w-10 h-10 flex items-center justify-center bg-neutral-600/80 hover:bg-neutral-600 border border-neutral-200
                        rounded-full shadow-lg hover:shadow-none transition-colors duration-300"
              role="button"
              onClick={this.handleZoomIn}
            >
              <IconZoomIn className="w-4 text-white/60 hover:text-white transition-colors duration-300" />
            </span>
          </div>
          <ImageModal open={open} onClose={() => this.setState({ open: false })} image={heroImage} />
        </div>
      </div>
    )
  }
}

export default Hero