import React, { Component, useRef, useEffect } from 'react'

function FadeInElement({ children }) {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('!opacity-100', 'translate-y-0');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.5
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div ref={elementRef} className="opacity-5 -translate-y-10 transition-all duration-1000 ease-in-out">
      {children}
    </div>
  );
}

class Benefits extends Component {

  render() {
    return (
      <div className="max-w-[1440px] mx-auto px-4 py-16">
        <FadeInElement>
          <div className="gradient-mask w-full relative">
            <div className={"bg-[url('./assets/images/band-concert.png')] bg-cover bg-center bg-no-repeat w-full h-[450px] rounded-lg"}></div>
            <div className="absolute inset-0 grid grid-cols-12 grid-rows-6 rounded-lg">
              {[...Array(72)].map((_, i) => (
                <div 
                  key={i}
                  className="border border-neutral-800/10 hover:bg-neutral-800/20 transition-all duration-300 ease-in-out"
                />
              ))}
            </div>
          </div>
        </FadeInElement>
        <div className="grid grid-rows-3 gap-10 md:grid-rows-none md:grid-cols-3 md:gap-20 mt-16">
          <div className="flex flex-col items-center text-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="curentColor" viewBox="-1 -1 40 40" height="40" width="40" className="stroke-neutral-100 dark:stroke-neutral-800">
              <path stroke="currentColor" d="M33.1512 14.531200000000002V29.427200000000003M9.3176 14.531200000000002V29.427200000000003M4.8488 14.531200000000002V29.427200000000003M28.682400000000005 14.531200000000002V29.427200000000003M4.104 32.4064H33.89600000000001M1.1248000000000002 36.1304H36.87520000000001M19 17.510400000000004H17.510400000000004C16.687700000000003 17.510400000000004 16.0208 18.177300000000002 16.0208 19V19.558600000000002C16.0208 20.220591666666667 16.4578 20.803258333333336 17.09335 20.98866666666667L20.906650000000003 22.101433333333336C21.542200000000005 22.286683333333333 21.979200000000002 22.86935 21.979200000000002 23.53134166666667L21.979200000000002 24.9584C21.979200000000002 25.781100000000006 21.3123 26.448000000000004 20.4896 26.448000000000004H19M19 17.510400000000004H20.4896C21.3123 17.510400000000004 21.979200000000002 18.177300000000002 21.979200000000002 19V19.7448M19 17.510400000000004V14.531200000000002M19 26.448000000000004H17.510400000000004C16.687700000000003 26.448000000000004 16.0208 25.781100000000006 16.0208 24.9584V24.213600000000003M19 26.448000000000004V29.427200000000003M36.1304 10.434800000000001V11.552000000000001H1.8696000000000004V10.434800000000001C9.3176 7.828 13.786400000000002 5.5936 18.6276 1.8696000000000004H19.372400000000003C24.213600000000003 5.5936 28.682400000000005 7.828 36.1304 10.434800000000001Z" strokeWidth="2"></path>
            </svg>
            <h3 className="text-2xl font-medium mt-3 mb-2">Tour-Ready Accounting</h3>
            <p>Track every tour expense and generate detailed reports in seconds. Manage vendor payments, reconcile accounts, and gain complete financial control, all from one platform.</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <svg width="41" height="40" viewBox="0 0 41 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="stroke-neutral-100 dark:stroke-neutral-800">
              <path d="M21.67 3.65834L33.3367 8.03334C33.9722 8.27156 34.5198 8.6981 34.9064 9.25593C35.293 9.81377 35.5001 10.4763 35.5 11.155V20.0933C35.5001 22.8791 34.7244 25.6097 33.2599 27.9794C31.7954 30.3491 29.6999 32.2642 27.2083 33.51L21.6183 36.305C21.2711 36.4787 20.8882 36.5691 20.5 36.5691C20.1118 36.5691 19.7289 36.4787 19.3817 36.305L13.7917 33.51C11.3001 32.2642 9.20461 30.3491 7.74011 27.9794C6.27562 25.6097 5.49994 22.8791 5.5 20.0933V11.155C5.49992 10.4763 5.70701 9.81377 6.09359 9.25593C6.48017 8.6981 7.02782 8.27156 7.66333 8.03334L19.33 3.65834C20.0844 3.37556 20.9156 3.37556 21.67 3.65834ZM20.5 6.78001L8.83333 11.155V20.0933C8.83372 22.2598 9.43732 24.3833 10.5765 26.2261C11.7157 28.0688 13.3455 29.558 15.2833 30.5267L20.5 33.1383L25.7167 30.53C27.655 29.561 29.2851 28.0714 30.4244 26.228C31.5636 24.3846 32.1669 22.2604 32.1667 20.0933V11.155L20.5 6.78001ZM20.5 13.3333C21.2114 13.333 21.9043 13.5604 22.4773 13.9821C23.0503 14.4038 23.4733 14.9978 23.6845 15.6772C23.8956 16.3566 23.8839 17.0857 23.651 17.7579C23.4181 18.4302 22.9762 19.0102 22.39 19.4133L22.1667 19.555V25C22.1662 25.4248 22.0035 25.8334 21.7119 26.1423C21.4203 26.4512 21.0217 26.6371 20.5977 26.662C20.1736 26.6869 19.756 26.5489 19.4303 26.2762C19.1045 26.0036 18.8952 25.6168 18.845 25.195L18.8333 25V19.555C18.1971 19.1885 17.6996 18.6221 17.4183 17.9438C17.137 17.2656 17.0874 16.5134 17.2774 15.8041C17.4674 15.0949 17.8863 14.4682 18.4691 14.0214C19.0518 13.5746 19.7657 13.3327 20.5 13.3333Z" fill="currentColor"/>
            </svg>
            <h3 className="text-2xl font-medium mt-3 mb-2">Block Chain Security</h3>
            <p>Encrypted and protected. Event Ledger safeguards your most crucial financial data with advanced technology and unwavering stability. </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <svg width="41" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-neutral-100 dark:stroke-neutral-800">
              <path d="M28.833 18.008c0-.576 0-.865.087-1.121.252-.747.917-1.035 1.583-1.339.747-.341 1.12-.511 1.492-.541.42-.034.842.056 1.202.258.476.267.81.777 1.15 1.19 1.571 1.91 2.358 2.865 2.645 3.917.233.85.233 1.74 0 2.588-.419 1.537-1.744 2.823-2.725 4.017-.502.608-.754.913-1.07 1.091a2.117 2.117 0 0 1-1.202.259c-.372-.03-.745-.2-1.493-.542-.667-.303-1.33-.592-1.582-1.338-.087-.257-.087-.545-.087-1.122v-7.317Zm-16.666 0c0-.726-.02-1.378-.607-1.888-.213-.185-.497-.313-1.062-.572-.748-.34-1.121-.511-1.493-.541-1.112-.09-1.71.67-2.35 1.45-1.573 1.908-2.36 2.863-2.648 3.916a4.9 4.9 0 0 0 0 2.589c.42 1.535 1.746 2.823 2.726 4.015.619.75 1.21 1.435 2.272 1.35.372-.03.745-.2 1.493-.542.567-.257.849-.387 1.062-.572.587-.51.607-1.161.607-1.886v-7.319Z" stroke="currentColor" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.833 15c0-5.523 5.224-10 11.667-10s11.667 4.477 11.667 10m0 13.333v1.334c0 2.945-2.984 5.333-6.667 5.333h-3.333" stroke="currentColor" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <h3 className="text-2xl font-medium mt-3 mb-2">VIP Support</h3>
            <p>Our dedicated support team is always on call to answer your questions and help you fine-tune your financial performance. Think of us as your personal financial roadies.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Benefits