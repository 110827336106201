import React, { Component } from "react";
import SectionHeading from "../../components/SectionHeading";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";

const faqs = [
  {question: 'Can Event Ledger handle complex royalty structures?', answer: '(Address the specific needs of music industry accounting, like international royalties and complex splits.)'},
  {question: 'How does Event Ledger help with tour budgeting and expense tracking?', answer: '(Showcase features relevant to managing tour finances.)'},
  {question: 'Is my data safe from unauthorized access?', answer: '(Reiterate your commitment to security, especially given the sensitivity of financial data in the music industry.)'},
  {question: 'Can I integrate with platforms like Spotify, Apple Music, and Bandcamp?', answer: '(Highlight integrations with popular music industry services.)'},
  {question: 'Do you offer customized solutions for artists, managers, and labels?', answer: '(If you have different plans or features, explain how they cater to various roles in the music business.)'},
]

class FAQ extends Component {
  render() {
    return (
      <div className="bg-neutral-800 text-white dark:bg-neutral-900">
        <div className="max-w-[1440px] mx-auto px-4 py-12">
          <div className="flex items-center gap-16 flex-col md:flex-row">
            <div className="basis-1/3">
              <SectionHeading
                title="FAQ"
                description="Have questions? We've got answers! From managing artist royalties and tour expenses to reconciling multi-currency 
                transactions and providing real-time financial reporting, our platform is designed to give you back time and control. Learn more about streamlining your accounting processes 
                and maximizing your artists' earnings."
                left
              />
            </div>
            <div className="basis-2/3">
              {faqs.map((item,idx) => (
              <Disclosure key={`faq-${idx}`} as="div" className="p-2 border rounded mb-2 dark:border-neutral-700">
                <DisclosureButton as="div" role="button" className="group text-[17px] font-medium flex gap-2">
                  <span className="before:content-['+'] group-data-[open]:before:content-['-']"></span>
                  <span>{item.question}</span>
                </DisclosureButton>
                <div className="overflow-hidden">
                  <DisclosurePanel
                    as="div"
                    transition
                    className="pl-4 text-[17px] text-slate-600 dark:text-neutral-400
                    origin-top transition duration-300 ease-in data-[closed]:-translate-y-6 data-[closed]:opacity-0"
                  >
                    {item.answer}
                  </DisclosurePanel>
                </div>
              </Disclosure>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FAQ