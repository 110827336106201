import React, { Component } from 'react'
import { Dialog, DialogPanel, Transition } from "@headlessui/react"

export default class ImageModal extends Component {
  render() {
    const { open, onClose, image } = this.props

    return (
      <Transition
        show={open}
        enter="duration-200 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-300 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog onClose={onClose}>
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-gray-950/90">
            <DialogPanel className="border bg-white dark:bg-neutral-700 dark:border-neutral-700 min-w-[1200px] rounded-md shadow-xl relative">
              <span
                role="button"
                className="absolute -right-5 -top-5 text-slate-300/50 hover:text-slate-300"
                onClick={onClose}
              >✕</span>
              <div className="rounded-lg">
                <img src={image} alt="Event Ledger" width={1200} className="max-w-none rounded-lg" />
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    )
  }
}
